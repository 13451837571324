import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const ProductsSlice = createSlice({
    name: 'FAQ',
    initialState: {
        products: [],
        product: null,
        callResponse: null,
        allCats: [],
        activeCats: []
    },
    reducers: {
        allProducts: (state, action) => {
            state.products = action.payload;
        },

        getProduct: (state, action) => {
            state.product = action.payload;
        },

        allCats: (state, action) => {
            state.allCats = action.payload;
        },

        allActiveCats: (state, action) => {
            state.activeCats = action.payload;
        },

        callResponse: (state, action) => {
            state.callResponse = action.payload;
        }
    }
})

const { allProducts, getProduct, callResponse, allCats, allActiveCats } = ProductsSlice.actions

export const getAllProducts = (cat_id) => async dispatch => {
    let response = await api.post('products/get_cat_products_by_admin', {cat_id}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allProducts(response.data.data));
    } else {
        dispatch(allProducts([]));
    }
}

export const getAllCats = () => async dispatch => {
    let response = await api.post('products/get_all_cats_by_admin', {}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allCats(response.data.data));
    } else {
        dispatch(allCats([]));
    }
}

export const getAllActiveCats = () => async dispatch => {
    let response = await api.post('products/get_all_cats', {}, {})
    if(response.data.status){
        dispatch(allActiveCats(response.data.data));
    } else {
        dispatch(allActiveCats([]));
    }
}

export const getProductDetail = (data) => async dispatch => {
    let response = await api.post('products/get', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(getProduct(response.data.data));
    } else {
        dispatch(getProduct(null));
    }
}

export const addNewProduct = (data) => async dispatch => {
    let response = await api.post('products/add', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const addNewCategory = (data) => async dispatch => {
    let response = await api.post('products/add_cat', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const updateProduct = (data) => async dispatch => {
    let response = await api.post('products/update', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const updateCategory = (data) => async dispatch => {
    let response = await api.post('products/update_cat', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export default ProductsSlice.reducer