import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../utils/api";

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const FAQSlice = createSlice({
    name: 'FAQ',
    initialState: {
        questions: [],
        callResponse: null,
        callImagesResponse: null
    },
    reducers: {
        allQuestions: (state, action) => {
            state.questions = action.payload;
        },

        callResponse: (state, action) => {
            state.callResponse = action.payload;
        },

        callImagesResponse: (state, action) => {
            state.callImagesResponse = action.payload;
        }
    }
})

const { allQuestions, callResponse, callImagesResponse } = FAQSlice.actions

export const getQuestions = () => async dispatch => {
    let response = await api.post('common/get_questions', {})
    if(response.data.status){
        dispatch(allQuestions(response.data.data));
    } else {
        dispatch(allQuestions([]));
    }
}

export const addQuestion = (data) => async dispatch => {
    let response = await api.post('common/add_question', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const updateQuestion = (data) => async dispatch => {
    let response = await api.post('common/update_question', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const deleteCommonData = (data) => async dispatch => {
    let response = await api.post('common/update_question', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const setCallImageResponse = (data) => async dispatch => {
    return dispatch(callImagesResponse(data))
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export const uploadAnswerVideo = (data, callBack) => async dispatch => {
    console.log(data)
    const form = new FormData();
    form.append("answer_video", data);
    let response = await api.post('common/upload_answer_video', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    if(response?.data?.status){
        callBack()
    } else {
        toast.error("error while uploading answer video")
        callBack()
    }
    dispatch(callImagesResponse(response.data))
}

export default FAQSlice.reducer