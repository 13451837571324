import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const blogsSlice = createSlice({
    name: 'blog',
    initialState: {
        blogs: [],
        blog: null,
        callResponse: null,
        callImagesResponse: null
    },

    reducers: {
        blogsSuccess: (state, action) => {
            state.blogs = action.payload;
        },

        blogSuccess: (state, action) => {
            state.blog = action.payload;
        },

        callResponse: (state, action) => {
            state.callResponse = action.payload;
        },

        callImagesResponse: (state, action) => {
            state.callImagesResponse = action.payload;
        }
    }
})


const { blogsSuccess, callResponse, callImagesResponse } = blogsSlice.actions


export const addBlog = (data) => async dispatch => {
    console.log("data : ", data)
    let response = await api.post('blog/add_new_blog', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    console.log("res : ", response.data)
    dispatch(callResponse(response.data));
}

export const addBlogImages = (data) => async dispatch => {
    const form = new FormData();
    for (const image of data) {
        form.append("blog_pics", image);
    }
    let response = await api.post('blog/upload_blogs_pic', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    dispatch(callImagesResponse(response.data))

}

export const addBlogImages2 = (data, cb) => async dispatch => {
    const form = new FormData();
    for (const image of data) {
        form.append("blog_pics", image);
    }
    let response = await api.post('blog/upload_blogs_pic', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    if(response?.data?.status){
        cb(response?.data?.data)
    } else {
        cb([])
    }
}

export const getBlogs = () => async dispatch => {
    let response = await api.post('blog/get_all_blogs', {}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if (response.data.status) {
        dispatch(blogsSuccess(response.data.data));
    } else {
        dispatch(blogsSuccess([]));
    }
}

export const getBlog = (data, callBack) => async dispatch => {
    let response = await api.post('blog/get', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if (response.data.status) {
        callBack(response.data.data)
    } else {
        callBack(null)
    }
}

export const updateBlog = (data) => async dispatch => {
    let response = await api.post('blog/update_blog', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const setCallImageResponse = (data) => async dispatch => {
    return dispatch(callImagesResponse(data))
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export default blogsSlice.reducer