import './App.css';
import 'antd/dist/antd.css';
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { HashRouter as Router, BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux"
import store from "./store"
import { onMessageListener, getToken } from "./firebaseNoti"
import { notification } from "antd"
import logoIcon from "./assets/logo_icon.png"
const AppRouter = lazy(() => import('./routes'))

const App = () => {
  const openNotification = (title, body, groupData) => {
    notification.open({
      message: <label style={{fontWeight:"bold"}}>New Message in {groupData?.grpData?.groupName} from : {title}</label>,
      description: body,
      className: 'custom-class',
      style: {
        width: 500,
      },
      icon: <img src={logoIcon} style={{height:"20px", marginTop:"20px", marginRight:"20px"}} />,
      onclick: () => {alert(groupData?.grpData?.groupName)}
    });
  };

  const [isTokenFound, setTokenFound] = useState(false);

  onMessageListener()
    .then((payload) => {
      let data = typeof payload?.data?.data == "string"?JSON.parse(payload?.data?.data):payload?.data?.data
      console.log(data)
      openNotification(payload.notification.title,payload.notification.body, data)
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    const temp = async () => {
      localStorage.setItem("fcm", await getToken(setTokenFound))
    }
    temp();
  },[])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router>
          <Suspense fallback={<div className={'text-center mt-3'}>Loading Please Wait....</div>}>
            <>
              <AppRouter />
            </>
          </Suspense>
        </Router>
      </BrowserRouter>
    </Provider>
  );
}

export default App;