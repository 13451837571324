import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        commonContent: null,
        callResponse: null,
        dashData: null,
        chatImages: null
    },

    reducers: {
        commonData: (state, action) => {
            state.commonContent = action.payload;
        },

        callResponse: (state, action) => {
            state.callResponse = action.payload;
        },
        setDashData: (state, action) => {
            state.dashData = action.payload;
        },
        chatImagesResponse: (state, action) => {
            state.chatImages = action.payload;
        }
    }
})

const { commonData, callResponse, setDashData, chatImagesResponse } = commonSlice.actions

export const getCommonData = () => async dispatch => {
    let response = await api.post('common/get_common_data', {}, { headers: { Authorization: `${initialToken}` } })
    if(response.data.status){
        dispatch(commonData(response.data.data));
    } else {
        dispatch(commonData(null));
    }
}

export const updateCommonData = (data) => async dispatch => {
    let response = await api.post('common/update_common_data', data, { headers: { Authorization: `${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const getDashData = () => async dispatch => {
    let response = await api.post('auth/get_dashboard_count', {}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(setDashData(response.data.data));
    }
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export const setChatImageResponse = (data) => async dispatch => {
    return dispatch(chatImagesResponse(data))
}

export const uploadChatMedia = (data, type) => async dispatch => {
    const form = new FormData();
    for (const image of data) {
        form.append("chat_media", image);
    }
    let response = await api.post('common/upload_chat_media', form, { headers: {'Content-Type': 'multipart/form-data' } })
    response = {...response?.data, other:type}
    dispatch(chatImagesResponse(response))
}

export default commonSlice.reducer

