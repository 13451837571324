import { configureStore } from '@reduxjs/toolkit';
import AdminReducer from './admin'
import AuthReducer from './user'
import ProductsReducer from './products'
import CommonReducer from './common'
import FAQReducer from './FAQ'
import BlogsReducer from './blog'
import knowledgeBarReducer from './knowledge_bar'
import jwtExpire from "../components/common/jwtExpire"

const token = localStorage.getItem('token');

if(token){
    jwtExpire(token)
}

const store = configureStore({
    reducer:{
        "admin":AdminReducer,
        "auth":AuthReducer,
        "products": ProductsReducer,
        "common":CommonReducer,
        "FAQ":FAQReducer,
        "blog":BlogsReducer,
        "knowledge_bar":knowledgeBarReducer,
    }
})

export default store;