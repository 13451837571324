import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const knowledgeBarSlice = createSlice({
    name: 'knowledgebar',
    initialState: {
        subjects: [],
        topics: [],
        callResponse: null,
        callImageResponse: null,
        callTopicPicsResponse: null
    },

    reducers:{

        setSubjects: (state, action) => {
            state.subjects = action.payload;
        },
        
        setTopics: (state, action) => {
            state.topics = action.payload;
        },

        callResponse: (state, action) => {
            state.callResponse = action.payload;
        },

        callImageResponse: (state, action) => {
            state.callImageResponse = action.payload;
        },

        callTopicPicsResponse: (state, action) => {
            state.callTopicPicsResponse = action.payload;
        }
    }
})

const { setSubjects, setTopics, callResponse, callImageResponse, callTopicPicsResponse } = knowledgeBarSlice.actions

export const getAllSubjects = () => async dispatch => {
    let response = await api.post('knowledge_bar/get_subjects', {}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(setSubjects(response.data.data));
    } else {
        dispatch(setSubjects([]));
    }
}

export const getSubjectTopics = (data) => async dispatch => {
    let response = await api.post('knowledge_bar/get_subject_topics', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(setTopics(response.data.data));
    } else {
        dispatch(setTopics([]));
    }
}

export const uploadTopicPics = (data) => async dispatch => {
    const form = new FormData();
    for (const image of data) {
        form.append("topic_pics", image);
    }
    let response = await api.post('knowledge_bar/upload_topic_pics', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    dispatch(callTopicPicsResponse(response.data))
}

export const uploadSubjectIcon = (data) => async dispatch => {
    console.log("data",  data)
    const form = new FormData();
    form.append("subject_icon", data);
    let response = await api.post('knowledge_bar/upload_subject_icon', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    dispatch(callImageResponse(response.data))
}

export const addNewSubject = (data) => async dispatch => {
    let response = await api.post('knowledge_bar/add_subject', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const updateSubject = (data) => async dispatch => {
    let response = await api.post('knowledge_bar/update_subject', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const addNewTopic = (data) => async dispatch => {
    let response = await api.post('knowledge_bar/add_topic', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const updateTopic = (data) => async dispatch => {
    let response = await api.post('knowledge_bar/update_topic', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const setCallImageResponse = (data) => async dispatch => {
    return dispatch(callImageResponse(data))
}

export const setTopicPicsResponse = (data) => async dispatch => {
    return dispatch(callTopicPicsResponse(data))
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export default knowledgeBarSlice.reducer