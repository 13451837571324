import firebase from 'firebase';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyD6Ls8Ax6S6qDxJigRG39aIrgXSdik_710",
  authDomain: "myedprep-33ee0.firebaseapp.com",
  projectId: "myedprep-33ee0",
  storageBucket: "myedprep-33ee0.appspot.com",
  messagingSenderId: "577341486066",
  appId: "1:577341486066:web:0bc634fb39483c2710f16b",
  measurementId: "G-W8W259NZ4W"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
const REACT_APP_VAPID_KEY = "BGtMxDQSTFE8ljY7M0l2bheFgWaEnN561EOw4CK9yNmVr_SpgKMJY4LUba0qkBibhEYdHFYYOYSybfK_ik7OV_I"
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};
const db = firebase.firestore();
export default db

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });