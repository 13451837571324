import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";
import * as FormData from 'form-data';

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: initialToken,
        image: null,
        loginMessage: "",
        callResponse: null,
        callImageResponse: null,
        allParents: [],
        allBillings: [],
        allStudents: [],
        allTutors: [],
        statesOfCountry: [],
        myDetails: null,
        parentData: null,
        studentsData: null,
        studentGrades: [],
        dealData: null,
        tutorRequests: []
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.admin_data;
            // state.loginMessage = action.payload.message.message;
            localStorage.setItem('token', state.token)
        },
        loginFailure: (state, action) => {
            state.loginMessage = action.payload.response.message;
        },
        userData: (state, action) => {
            const userData = action.payload.user;
            state.user = userData;
        },
        uploadImage: (state, action) => {
            state.image = action.payload.res;
        },
        logoutSuccess: (state, action) => {
            state.token = null;
            state.user = null;
            state.loginMessage = "";
            localStorage.removeItem('token')
        },
        callResponse: (state, action) => {
            state.callResponse = action.payload;
        },
        callImageResponse: (state, action) => {
            state.callImageResponse = action.payload;
        },
        allParents: (state, action) => {
            state.allParents = action.payload;
        },
        allStudents: (state, action) => {
            state.allStudents = action.payload;
        },
        allBillings: (state, action) => {
            state.allBillings = action.payload;
        },
        myInvoices: (state, action) => {
            state.myInvoices = action.payload;
        },
        allTutors: (state, action) => {
            state.allTutors = action.payload;
        },
        statesOfCountry: (state, action) => {
            state.statesOfCountry = action.payload;
        },
        myDetails: (state, action) => {
            state.myDetails = action.payload;
        },
        parentData: (state, action) => {
            state.parentData = action.payload;
        },
        studentsData: (state, action) => {
            state.studentsData = action.payload;
        },
        studentGrades: (state, action) => {
            state.studentGrades = action.payload;
        },
        dealData: (state, action) => {
            state.dealData = action.payload;
        },
        tutorRequests: (state, action) => {
            state.tutorRequests = action.payload;
        },
    }
})

const { loginSuccess, loginFailure, logoutSuccess, userData, uploadImage, callResponse, callImageResponse, allParents, allStudents, 
    allTutors, statesOfCountry, myDetails, parentData, studentsData, dealData, studentGrades, allBillings, myInvoices, tutorRequests } = authSlice.actions

export const login = ({ email, password, fcm_token }) => async dispatch => {
    let loginResponse = await api.post('administration/login', { email, password, fcm_token })
    if (loginResponse.data.status) {
        let token = loginResponse.data.data.token
        let admin_data = loginResponse.data.data.admin_data
        dispatch(loginSuccess({ token, admin_data }));
    } else {
        dispatch(loginFailure({response: loginResponse.data}))
    }
}

export const forgotPasswdMial = ({ email }) => async dispatch => {
    try {
        let res = await api.post('administration/forgot', { email })
        if (res) {
            return true
        }
    } catch (e) {
        return console.error(e.message);
    }
}

export const reset_password = ({ email, otp, password }) => async dispatch => {
    try {
        let res = await api.post('administration/reset_password', { email, otp, password })
        if(res)
            return true
        
        return false
    } catch (e) {
        return console.error(e.message);
    }
}

export const change_password = ({ old_password, new_password }) => async dispatch => {
    let response = await api.post('administration/change_password', { old_password, new_password })
    dispatch(callResponse(response.data))
}

export const userDetails = () => async dispatch => {
    let response = await api.post('administration/get', {}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(userData({ user: response.data.data }));
    } else {
        dispatch(logoutSuccess())
    }
}

export const notifyUsers = (data) => async dispatch => {
    await api.post('auth/notify_other_user', data, {})
}

export const logout = () => async dispatch => {
    try {
        return dispatch(logoutSuccess())
    } catch (e) {
        return console.error(e.message);
    }
}

export const updateAdministrator = (data) => async dispatch => {
    let response = await api.post('administration/update', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    return dispatch(callResponse(response.data))
}

export const updateAdministratorPassword = (data) => async dispatch => {
    let response = await api.post('administration/change_password', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    return dispatch(callResponse(response.data))
}

export const uploadFileHandler = ({ file }) => async dispatch => {
    try {
        const form = new FormData();
        for (var i = 0; i < file.length; i++) {
            form.append(`images`, file[i].originFileObj);
        }
        form.append("userId", '615322a78856892c0e63379d');
        let res = await api.post('/api/auth/change_profile_pic', form, { headers: { 'Authorization': `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
        return dispatch(uploadImage({ img: res }));
    } catch (error) {
        console.error(error.message);
    }
}

export const uploadStudentExcelFile = (file, cb) => async dispatch => {
    try {
        const form = new FormData();
        form.append(`data_excel`, file);
        let res = await api.post('auth/upload_excel', form, { headers: { 'Authorization': `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
        cb(res?.data)
    } catch (error) {
        console.error(error.message);
    }
}

export const uploadParentExcelFile = (file, cb) => async dispatch => {
    try {
        const form = new FormData();
        form.append(`data_excel`, file);
        let res = await api.post('auth/upload_parent_excel', form, { headers: { 'Authorization': `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
        cb(res?.data)
    } catch (error) {
        console.error(error.message);
    }
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export const addImage = (data) => async dispatch => {
    const form = new FormData();
    form.append(`admin_profile`, data);
    let response = await api.post('administration/change_profile_pic', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    console.log("response : ",response)
    dispatch(callImageResponse(response.data))
}

export const setCallImageResponse = (data) => async dispatch => {
    return dispatch(callImageResponse(data))
}

export const getAllParents = () => async dispatch => {
    let response = await api.post('auth/get_all_users', { role: "parent" }, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allParents(response.data.data));
    } else {
        dispatch(allParents([]));
    }
}

export const getAllStudents = () => async dispatch => {
    let response = await api.post('auth/get_all_users', { role: "student" }, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allStudents(response.data.data));
    } else {
        dispatch(allStudents([]));
    }
}

export const searchStudent = (data) => async dispatch => {
    let response = await api.post('auth/search_student_by_name', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allStudents(response.data.data));
    } else {
        dispatch(allStudents([]));
    }
}

export const searchParent = (data) => async dispatch => {
    let response = await api.post('auth/search_parent_by_name', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allParents(response.data.data));
    } else {
        dispatch(allParents([]));
    }
}

export const searchTutor = (data) => async dispatch => {
    let response = await api.post('tutor/search_tutor_by_name', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allTutors(response.data.data));
    } else {
        dispatch(allTutors([]));
    }
}

export const getAllBillings = (data) => async dispatch => {
    let response = await api.post('auth/get_all_billings', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allBillings(response.data.data));
    } else {
        dispatch(allBillings([]));
    }
}

export const createInvoice = (data) => async dispatch => {
    let response = await api.post('auth/create_invoice', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const remind = (data) => async dispatch => {
    let response = await api.post('auth/remind', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const updateInvoice = (data) => async dispatch => {
    let response = await api.post('auth/update_invoice', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const getMyInvoices = (data) => async dispatch => {
    let response = await api.post('auth/get_my_invoices', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(myInvoices(response.data.data));
    } else {
        dispatch(myInvoices([]));
    }
}

export const getAllTuTors = () => async dispatch => {
    let response = await api.post('auth/get_all_users', { role: "tutor" }, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(allTutors(response.data.data));
    } else {
        dispatch(allTutors([]));
    }
}

export const getStatesOfCountry = () => async dispatch => {
    let response = await api.post('auth/get_states_of_country', {})
    if(response.data.status){
        dispatch(statesOfCountry(response.data.data));
    } else {
        dispatch(statesOfCountry([]));
    }
}

export const updateUser = (data, callback) => async dispatch => {
    let response = await api.post('auth/update_user', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(callback){
        callback(response?.data)
    }
    return dispatch(callResponse(response.data))
}

export const storeClassData = (data) => async dispatch => {
    let response = await api.post('grade/store_class_data', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(getStudentGrades({ user_id: data.student_id }))
    return dispatch(callResponse(response.data))
}

export const addTutor = (data, successCB) => async dispatch => {
    let response = await api.post('auth/tutor_register', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(successCB && response?.data?.status){
        successCB(response?.data?.data)
    }
    return dispatch(callResponse(response.data))
}

export const getMyDetails = (data) => async dispatch => {
    let response = await api.post('auth/get_detail', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(myDetails(response.data.data))
    } else {
        dispatch(myDetails(null))
    }
}

export const getMyParent = (data) => async dispatch => {
    let response = await api.post('auth/get_my_parents', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(parentData(response.data.data))
    } else {
        dispatch(parentData(null))
    }
}

export const getParentStudents = (data) => async dispatch => {
    let response = await api.post('auth/get_parent_students', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(studentsData(response.data.data))
    } else {
        dispatch(studentsData(null))
    }
}

export const getAllTutorRequests = () => async dispatch => {
    let response = await api.post('tutor/get_all_tutor_requests', {}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(tutorRequests(response.data.data))
    } else {
        dispatch(tutorRequests(null))
    }
}

export const updateTutorRequest = (data) => async dispatch => {
    let response = await api.post('tutor/update_tutor_request', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(setCallResponse(response.data))
}

export const getDealData = (data) => async dispatch => {
    let response = await api.post('auth/get_deal_detail', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(dealData(response.data.data))
    } else {
        dispatch(dealData(null))
    }
}

export const getUserBasicInfo = (user_id) => async dispatch => {
    let response = await api.post('auth/get_user_basic_info', {user_id}, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        return response.data.data
    } else {
        return null
    }
}

export const getStudentGrades = (data) => async dispatch => {
    let response = await api.post('grade/get_student_grades_by_admin', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    if(response.data.status){
        dispatch(studentGrades(response.data.data))
    } else {
        dispatch(studentGrades([{
            grade_semester: "Semester 1",
            grade_data: [{
                course_name: "",
                credits: 1,
                grade: "",
                course_type: "Regular",
            }],
            sem_weighted: 0.0,
            sem_unweighted: 0.0
        }]))
    }
}

export const uploadStreamIcon = (data) => async dispatch => {
    const form = new FormData();
    form.append(`stream_icon`, data);
    let response = await api.post('auth/upload_stream_icon', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    dispatch(callImageResponse({...response.data, type: "stream"}))
}

export const uploadGroupIcon = (data) => async dispatch => {
    const form = new FormData();
    form.append(`stream_icon`, data);
    let response = await api.post('auth/upload_stream_icon', form, { headers: { Authorization: `Bearer ${initialToken}`, 'Content-Type': 'multipart/form-data' } })
    dispatch(callImageResponse({...response.data, type: "group"}))
}

export default authSlice.reducer