import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";

const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        admins: [],
        callResponse: null,
        callImageResponse: null,
        adminNotifications: []
    },
    reducers: {
        allAdmins: (state, action) => {
            state.admins = action.payload;
        },

        callResponse: (state, action) => {
            state.callResponse = action.payload;
        },

        callImageResponse: (state, action) => {
            state.callImageResponse = action.payload;
        },

        adminNotis: (state, action) => {
            state.adminNotifications = action.payload;
        }
    }
})

const { allAdmins, callResponse, adminNotis } = adminSlice.actions

export const getAdmins = () => async dispatch => {
    let response = await api.post('administration/get_all_admins', {}, { headers: { Authorization: `Bearer ${initialToken}` }})
    if(response.data.status){
        dispatch(allAdmins(response.data.data));
    } else {
        dispatch(allAdmins([]));
    }
}

export const getAllAdminNotis = (admin_id) => async dispatch => {
    let response = await api.post('auth/get_all_admin_notis', {admin_id}, {})
    if(response.data.status){
        dispatch(adminNotis(response.data.data));
    } else {
        dispatch(adminNotis([]));
    }
}
export const readAdminNotis = (data) => async dispatch => {
    let response = await api.post('auth/read_admin_notis', data, {})
    if(response.data.status){
        getAllAdminNotis()
    }
}

export const addAdmin = (data) => async dispatch => {
    let response = await api.post('administration/add_new_admin', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const updateAdmin = (data) => async dispatch => {
    let response = await api.post('administration/update_admin', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data))
}

export const deleteCommonData = (data) => async dispatch => {
    let response = await api.post('administration/update_admin', data, { headers: { Authorization: `Bearer ${initialToken}` } })
    dispatch(callResponse(response.data));
}

export const changePassword = (data) => async dispatch => {
    let response = await api.post('administration/change_password_by_admin_id', data)
    dispatch(callResponse(response.data))
}

export const setCallResponse = (data) => async dispatch => {
    return dispatch(callResponse(data))
}

export default adminSlice.reducer